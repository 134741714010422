<i18n locale="th" lang="yaml" >
page.title : "Privacy Policy"
page.back : "กลับสู่หน้าหลัก"
</i18n>

<template>
	<div id="privacy_policy_user_page">
		<div class="content">
			<PrivacyPolicyContent/>
			<div class="back-style">
      	<my-router-link  id="privacy_policy_back_button_link" class="back" name="dashboard" no-param >
        	{{$t('page.back')}}
      	</my-router-link>
			</div>
		</div>
	</div>

</template>


<script>
import PrivacyPolicyContent from "@/src/components/common/PrivacyPolicyContent.vue"
export default {
	components : {
		PrivacyPolicyContent
	} ,
	page() {
		return {
			title: this.$t('page.title'),
		}
	},

	data() {
		return {

		}
	} ,

	computed : {

	} ,
	methods : {

	}

}

</script>

<style lang="less" scoped>

.content {
   margin: 2vw;
}

.back-style {
	margin-top: 3vw;
	justify-content: center;
	text-align: center;
	padding-bottom: 2vw;
}

.back {
	font-size: 1.5em;
}
</style>
